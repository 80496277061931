export function setParam ({ paramName, paramValue, currentWindow = window }: {
  paramName: string;
  paramValue: unknown;
  currentWindow?: Window;
}) {
  const urlParams = setUrlParamAndDoNotApply({ paramName, paramValue, currentWindow });
  const baseUrl = currentWindow.location.href.split('?')[0];
  currentWindow.history.replaceState(null, '', `${baseUrl}?${urlParams.toString()}`);
}

export function setUrlParamAndDoNotApply ({ paramName, paramValue, currentWindow = window }: {
  paramName: string;
  paramValue: unknown;
  currentWindow?: Window;
}, urlSearchParams = new URLSearchParams(currentWindow.location.search)) {
  urlSearchParams.set(paramName, String(paramValue));
  return urlSearchParams;
}

export function deleteUrlParam (name: string) {
  const urlSearchParams = deleteUrlParamAndDoNotApply({ name });
  history.replaceState(null, '', getUrl(urlSearchParams));
}

export function deleteUrlParamAndDoNotApply ({ name }: { name: string }, urlSearchParams = new URLSearchParams(window.location.search)) {
  urlSearchParams.delete(name);
  return urlSearchParams;
}

export function getUrlParam ({ name }: { name: string }, urlSearchParams = new URLSearchParams(location.search)) {
  return urlSearchParams.get(name);
}

export function getUrl (urlSearchParams: URLSearchParams) {
  const baseUrl = location.href.split('?')[0];
  if (urlSearchParams.size === 0) {
    return baseUrl;
  }
  return `${baseUrl}?${urlSearchParams}`;
}

export function getParametersStartingWith (searchParams: URLSearchParams, string: string) {
  const foundParameters = new Map();
  searchParams.forEach((value, key) => {
    if (key.startsWith(string)) {
      foundParameters.set(key, value);
    }
  });
  return foundParameters;
}

export function hasParameter (paramName: string) {
  const urlSearchParams = new URLSearchParams(location.search);
  return urlSearchParams.has(paramName);
}

/**
 * Parses the query part of the given url string and returns a key-value object with its parameters.
 * The value is an array, because a key can exists multiple times.
 *
 * @param urlString
 * @return Parameters as a { key: value } object
 */
export function getUrlParameters (urlString: string) {
  const [ , queryString ] = urlString.split('?');
  const params: Record<string, string[]> = {};
  const searchParams = new URLSearchParams(queryString);
  searchParams.forEach((value, key) => {
    const val = params[key];
    if (val === undefined) {
      params[key] = [ value ];
    } else {
      val.push(value);
    }
  });
  return params;
}

/**
 * Extend a given URL with the given parameters map as query parameters.
 *
 * Note: If the given URL already contains one or more parameters with the same name as one of the new
 * parameters specified, it or they will be replaced by the new value.
 *
 * @param urlString - The URL to extend
 * @param params - A map-like object of parameters top add to the URL
 * @return A string containing the original URL with the added query parameters
 */
export function extendUrlParameters (urlString: string, params: Record<string, unknown | unknown[]> = {}) {
  const [ baseUrl, queryString ] = urlString.split('?');
  const searchParams = new URLSearchParams(queryString);
  for (const [ paramName, paramValue ] of Object.entries(params)) {
    if (Array.isArray(paramValue)) {
      paramValue.forEach(value => searchParams.append(paramName, value));
    } else {
      searchParams.set(paramName, String(paramValue));
    }
  }
  const paramsUrlPart = searchParams.toString();
  return paramsUrlPart ? baseUrl + '?' + paramsUrlPart : baseUrl;
}

/**
 * Modify a given URL by removing the given parameters from the query string section, if present.
 *
 * @param urlString - The URL to modify
 * @param paramNames - The name(s) of the query parameter(s) to remove
 * @return A string containing the original URL minus the removed query parameters
 */
export function removeUrlParameters (urlString: string, paramNames: string | string[]) {
  if (!Array.isArray(paramNames)) {
    paramNames = [ paramNames ];
  }

  const [ baseUrl, queryString ] = urlString.split('?');
  const searchParams = new URLSearchParams(queryString);
  paramNames.forEach(paramName => searchParams.delete(paramName));
  const paramsUrlPart = searchParams.toString();
  return paramsUrlPart ? baseUrl + '?' + paramsUrlPart : baseUrl;
}

/**
 * Like getUrlParameters, but also converts boolean and numbers to their
 * corresponding JavaScript representation
 *
 * @param urlString
 * @return Parameters
 */
export function getParsedUrlParameters (urlString: string) {
  return Object.fromEntries(Object.entries(getUrlParameters(urlString))
    .map(([ param, values ]) => [ param, values
      .map(value => {
        if (value === 'true') {
          return true;
        } else if (value === 'false') {
          return false;
        } else if (!isNaN(Number(value))) {
          const valueAsFloat = parseFloat(value);
          if (!isNaN(valueAsFloat)) {
            return valueAsFloat;
          }
        } else {
          return value;
        }
      })
    ]));
}

import relativeLuminance from 'relative-luminance';

import { assert } from 'cadenza/utils/custom-error';
import { logger } from 'cadenza/utils/logging';
import { clamp, roundToFloatOrInt } from 'cadenza/utils/math';

/** Red, Green, Blue */
export type Rgb = [ number, number, number ];
/** Red, Green, Blue, Alpha */
export type Rgba = [ number, number, number, number ];
/** Hue, Saturation, Lightness */
type Hsl = [ number, number, number ];
/** Color values in any supported format */
type ColorValues = Rgb | Rgba;

/** Color in #rrggbb format */
export type HexColor = `#${string}`;
/** Color in rgba(r, g, b, a) format */
export type RgbaColor = `rgba(${string})`;

/** Color in any supported format */
export type Color = HexColor | RgbaColor;

export type ColorOrValues = Color | ColorValues;

/** @see net/disy/cadenza/workbook/web/dto/ColorPaletteDto.java */
export interface ColorPaletteDto {
  id: string;
  title: string;
  colors: Color[];
}

const INVALID_COLOR_PALETTE_ID = 'invalid-color-palette-id';
const GRADIENT_OPACITY_FACTORS = [ 0.0, 0.29, 0.5, 0.66, 0.77, 0.87, 0.94, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0 ];

/**
 * We need to have invalid color palette that has display: none; so that the following happens:
 * When the user selects the first element in select, the change event is fired.
 * For colors we create an array of 16 elements
 * so that in the preview 16 circles are shown. Colors are not important because they are set to
 * transparent via the css.
 * This should be refactored to use the placeholder in the d-select component.
 */
export const INVALID_COLOR_PALETTE: ColorPaletteDto = {
  id: INVALID_COLOR_PALETTE_ID,
  title: '', // Title is not shown, so it's not necessary.
  colors: Array.from({
    length: 16
  }, () => '#ffffff')
};

// Default light color palette.
// The colors were picked to have a good contrast to each other, also with respect of colorblindness.
export const DEFAULT_COLOR_PALETTE = Object.freeze([ '#caca44', '#ffe99a', '#f8bea3', '#d19ac8', '#a3e9f8', '#63be75', '#ebec8b', '#6b6cce', '#f8a3b0', '#dfdfdf', '#52a46e', '#93c6e5', '#c7e04f', '#be6394', '#85dfca', '#88a9d5', '#f1f1f1', '#4fbac8' ]) as readonly Color[];

// Default strong color palette.
// The colors are more shiny than in the default palette, meant for small points, which still should be visible on a common map background.
// Avoid usage of this palette for bigger surfaces, the colors will be too strong.
export const STRONG_COLOR_PALETTE = Object.freeze([ '#0c47e7', '#fb2b00', '#13f3ff', '#32fd09', '#8d6f68', '#ef47ff', '#f8d334', '#46b300' ]) as readonly Color[];

// This palette is used in the attribute modification dialog for rule-based highlighting
export const ATTRIBUTE_HIGHLIGHTING_PALETTE = Object.freeze([ '#353b44', '#ffffff', '#00960f', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffbd07', '#ff9100', '#ff4f22', '#e82222' ]) as readonly Color[];
export const DEFAULT_ATTRIBUTE_HIGHLIGHTING_BACKGROUND_COLOR = '#00960f';
export const DEFAULT_ATTRIBUTE_HIGHLIGHTING_FONT_COLOR = '#353b44';

// These Regex'es are incomplete – Correct Channel value range is ensured separately.
const RGBA_COLOR_REGEX = /^rgba\(\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*([01]?(\.\d+)?)\)$/;
const RGB_COLOR_REGEX = /^rgb\(\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;
const HEX_COLOR_REGEX = /^#[A-Fa-f0-9]{6}$/;

/**
 * Colors from `color-variables.css` made available for JS
 *
 * _Note_: Properties are in snake case, e.g. `GRAY_08` instead of `--gray-08`.
 */
export const COLORS = new Proxy(getComputedStyle(document.documentElement), {
  get (target, prop: string) {
    const value = target.getPropertyValue('--' + toDashCase(prop));
    if (!value) {
      logger.log(`Unknown color "${prop}", see color-variables.css for known colors"`);
    }
    return value.trim();
  }
}) as unknown as Record<string, Color>;

/**
 * Converts for example `GRAY_08` to `gray-08`.
 *
 * @param str - The input string
 * @return The output string in dash case
 */
function toDashCase (str: string) {
  return str.toLowerCase().replace(/_/g, '-');
}

export function isColor (value: string): value is Color {
  return isHexColor(value) || isRgbaColor(value) || isRgbColor(value);
}

export function isHexColor (value: string): value is HexColor {
  return HEX_COLOR_REGEX.test(sanitize(value));
}

export function isRgbaColor (value: string): value is RgbaColor {
  return RGBA_COLOR_REGEX.test(sanitize(value));
}

export function isRgbColor (value: string): value is RgbaColor {
  return RGB_COLOR_REGEX.test(sanitize(value));
}

function sanitize (string?: string | null) {
  return string ? string.trim() : '';
}

export function toHexColor (value: ColorOrValues): HexColor {
  const [ r, g, b ] = parseColor(value);
  return '#' + [ r, g, b ].map(n => n.toString(16).padStart(2, '0')).join('') as HexColor;
}

export function toRgbaColor (value: ColorOrValues): RgbaColor {
  const [ r, g, b, a ] = parseColor(value);
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export function isWhite (color: ColorOrValues) {
  const [ r, g, b, a ] = parseColor(color);
  return [ r, g, b ].every(value => value === 255) && a === 1;
}

export function parseColor (value: ColorOrValues): Rgba {
  if (typeof value === 'string') {
    assert(isColor(value), `Unsupported color: ${value}`);
    value = isHexColor(value) ? [ ...parseHexColor(value), 1 ] : parseRgbaColor(value);
  }

  const [ r, g, b, a = 1 ] = value;
  return [
    clamp255(r),
    clamp255(g),
    clamp255(b),
    clampAlpha(a)
  ];
}

function parseHexColor (value: string): Rgb {
  value = sanitize(value);
  assert(isHexColor(value), `Not a hex color: ${value}`);
  const hex = parseInt(value.substring(1), 16);
  return [
    (hex >> 16) & 255,
    (hex >> 8) & 255,
    (hex) & 255
  ];
}

export function parseRgbaColor (value: string): Rgba {
  const match = RGBA_COLOR_REGEX.exec(sanitize(value)) || RGB_COLOR_REGEX.exec(sanitize(value));
  assert(match, `Not an rgb(a) color: ${value}`);
  return [
    parseInt(match[1]),
    parseInt(match[2]),
    parseInt(match[3]),
    parseFloat(match[4] || '1')
  ];
}

export function hasTransparency (color: ColorOrValues) {
  return getAlpha(color) < 1;
}

function getAlpha (color: ColorOrValues) {
  return parseColor(color)[3];
}

/**
 * Applies new opacity value to the rgba or hex color and returns new color.
 * If opacity is not provided, returns the color as rgba.
 *
 * @param color - color as rgba string ('rgba(77, 175, 74, 1)') or as hex ('#4daf4a'), to which opacity will be added
 * @param opacity - opacity in range [0.0, 1.0]
 * @return Returns new color with opacity
 * @example
 *   getColorWithOpacity('rgba(77, 175, 74, 1)', 0.3); // returns 'rgba(77, 175, 74, 0.3)'
 *   getColorWithOpacity('rgba(77, 175, 74, 0.5)', 0.3); // returns 'rgba(77, 175, 74, 0.3)'
 *   getColorWithOpacity('rgba(77, 175, 74, 0.5)'); // returns 'rgba(77, 175, 74, 0.5)'
 */
export function getColorWithOpacity (color: ColorOrValues, opacity?: number): RgbaColor {
  if (opacity == null) {
    return toRgbaColor(color);
  }
  const [ r, g, b ] = parseColor(color);
  return toRgbaColor([ r, g, b, clampAlpha(opacity) ]);
}

/**
 * Applies opacity factor to the rgba color by setting the opacity to a previous opacity multiplied by the given opacity factor and returns new color.
 *
 * @param color - color as rgba string ('rgba(77, 175, 74, 1)') or in hex format to which opacity will be applied
 * @param opacityFactor - opacityFactor in range [0.0, 1.0]
 * @return Returns new color with applied opacity factor
 * @example
 *   applyOpacityFactorToColor('rgba(77, 175, 74, 1)', 0.3); // returns 'rgba(77, 175, 74, 0.3)'
 *   applyOpacityFactorToColor('rgba(77, 175, 74, 0.5)', 0.3); // returns 'rgba(77, 175, 74, 0.15)'
 */
export function applyOpacityFactorToColor (color: ColorOrValues, opacityFactor: number): RgbaColor {
  return getColorWithOpacity(color, getAlpha(color) * opacityFactor);
}

export function getOpacity (color: ColorOrValues) {
  let colorArray: number[] = [];
  if (typeof color === 'string') {
    colorArray = parseColor(color);
  }
  return (colorArray.length === 4) ? colorArray[3] : 1;
}

export function getOpacityDecimalValueFromPercentage (opacityPercentage: number) {
  return Number((opacityPercentage / 100).toFixed(2));
}

/**
 * Create a shade lighter or darker of a given color.
 * Each channel (r, g, b) is increased or decreased of the given amount multiplied by 255.
 * This has the drawback that it is nor proportional and might be noticeable for big values of fraction.
 * On the other hand it is easy to reason about and works perfectly for zero values too.
 * This will work better for smaller amount.
 *
 * @param value - color as rgba string ('rgba(77, 175, 74, 1)')
 * @param amount - A float in range [-1, 1]
 * @return The original color darkened or lightened by the given amount
 */
export function colorShade (value: ColorOrValues, amount: number): RgbaColor {
  assert(Math.abs(amount) <= 1, 'Percentage must be within the range [-1, 1]');
  const [ r, g, b, a ] = parseColor(value);
  return toRgbaColor([
    +addToChannel(r, amount),
    +addToChannel(g, amount),
    +addToChannel(b, amount),
    +a
  ]);
}

function addToChannel (value: number, amount: number) {
  return clamp255(value + (amount * 255));
}

export function rgbToHsl ([ red, green, blue ]: Rgb): Hsl {
  const r = red / 255;
  const g = green / 255;
  const b = blue / 255;

  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);
  const delta = max - min;

  let hueCalc;
  if (delta === 0) {
    hueCalc = 0;
  } else if (max === r) {
    hueCalc = ((g - b) / delta) % 6;
  } else if (max === g) {
    hueCalc = (b - r) / delta + 2;
  } else {
    hueCalc = (r - g) / delta + 4;
  }

  const hue = Math.round(((hueCalc * 60) + 360) % 360);
  const lightness = (max + min) / 2;
  const saturation = delta === 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1));

  return [ hue, roundToFloatOrInt(saturation, 2), roundToFloatOrInt(lightness, 2) ];
}

export function hslToRgb ([ h, s, l ]: Hsl): Rgb {
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs((h / 60) % 2 - 1));
  const m = l - c / 2;

  let r!: number;
  let g!: number;
  let b!: number;

  if (h >= 0 && h < 60) {
    r = c;
    g = x;
    b = 0;
  }
  if (h >= 60 && h < 120) {
    r = x;
    g = c;
    b = 0;
  }
  if (h >= 120 && h < 180) {
    r = 0;
    g = c;
    b = x;
  }
  if (h >= 180 && h < 240) {
    r = 0;
    g = x;
    b = c;
  }
  if (h >= 240 && h < 300) {
    r = x;
    g = 0;
    b = c;
  }
  if (h >= 300 && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return [ r, g, b ];
}

const LUMINOSITY_THRESHOLD = 0.45;
const SHADE_COLOR_DISTANCE = 0.3;
const SHADE_COLOR_DISTANCE_INVERSE = 0.2;

/**
 * Calculates a different shade of this color, for example to use a border when the filling color is this color.
 * It can be used e.g. to draw point, on a map or in 3D.
 * The shade color is darker for bright colors, and lighter for dark colors.
 * If the inverse parameter is set to true, then it's the opposite.
 * This can be used to obtain an alternative for example for a 'hover' state.
 *
 * @param value - the base color
 * @param inverse - if inverse is true the logic is reversed
 * @return a shade darker or lighter of the original color
 */
export function shadeOf (value: ColorOrValues, inverse = false): RgbaColor {
  const [ r, g, b ] = parseColor(value);
  const [ hue, saturation, lightness ] = rgbToHsl([ r, g, b ]);
  let result;

  const isBright = lightness > LUMINOSITY_THRESHOLD;

  const shadeLuminosityChange = inverse ? SHADE_COLOR_DISTANCE_INVERSE : SHADE_COLOR_DISTANCE;
  if ((isBright && !inverse) || (!isBright && inverse)) {
    result = hslToRgb([ hue, saturation, Math.max(0, lightness - shadeLuminosityChange) ]);
  } else {
    // scale brightness adjustment by the inverse luminosity as an approximation of
    // fixed perceptual difference between base color and derived color.
    const lumX6 = luminosityX6([ r, g, b ]);
    const targetLightness = lightness + Math.min(shadeLuminosityChange, (shadeLuminosityChange * 6) / lumX6);
    result = hslToRgb([ hue, saturation, Math.min(1.0, targetLightness) ]);

    // if the change cannot be completed because it clips in brightness, increase every RGB element
    if (targetLightness > 1.0) {
      const colorChange = (100 * Math.min(1.0, (targetLightness - 1.0)));
      result = result.map(v => clamp255(v + colorChange)) as Rgb;
    }
  }
  return toRgbaColor([ ...result, 1 ]);
}

function clamp255 (value: number) {
  return clamp(Math.round(value), 0, 255);
}

function clampAlpha (value: number) {
  return clamp(value, 0, 1);
}

/**
 * Approximate the luminosity of the RGB color.
 * Correct equation via ITU BT.601: Y = 0.299 R + 0.587 G + 0.114 B
 *
 * @param rgb - Rgb values
 * @return luminosity times 6
 */
function luminosityX6 ([ r, g, b ]: Rgb) {
  return (2 * r + 3 * g + b) / 255;
}

export function interpolateRgbColor ([ r1, g1, b1 ]: Rgba, [ r2, g2, b2 ]: Rgba, fraction: number): Rgb;
export function interpolateRgbColor ([ r1, g1, b1 ]: Rgb, [ r2, g2, b2 ]: Rgb, fraction: number): Rgb;
/**
 * Interpolate between 2 RGB colors by the given fraction.
 * R G and B are linearly interpolated separately.
 * The interpolated values are rounded.
 *
 * For convenience, the function also accepts RGBA components.
 * In this case the A (alpha) component is simply ignored.
 *
 * @param col1 - RGB components for the first color
 * @param col2 - RGB components for the second color
 * @param fraction - A number between 0.0 (yields the first color) and 1.0 (yields the second color).
 * @return Returns the R, G and B components of the interpolated color.
 */
export function interpolateRgbColor ([ r1, g1, b1 ]: Rgb | Rgba, [ r2, g2, b2 ]: Rgb | Rgba, fraction: number): Rgb {
  assert(fraction >= 0 && fraction <= 1.0, 'Fraction must be between 0 and 1');
  return [
    interpolateComponent(r1, r2, fraction),
    interpolateComponent(g1, g2, fraction),
    interpolateComponent(b1, b2, fraction)
  ];
}

function interpolateComponent (value1: number, value2: number, fraction: number) {
  const diff = value2 - value1;
  return Math.round(value1 + fraction * diff);
}

export function isDark (color: ColorOrValues, threshold = 0.45) {
  // The relative luminance is defined relative to white,
  // so we mix colors with an alpha channel with white first.
  return relativeLuminance(mixRgbaWithBackground(parseColor(color))) <= threshold;
}

/**
 * Mixes a color with alpha channel with a given background color, so that it becomes opaque.
 *
 * The default background color is white.
 *
 * @see https://stackoverflow.com/a/47024391
 */
function mixRgbaWithBackground ([ r1, g1, b1, a ]: Rgba, [ r2, g2, b2 ]: Rgb = [ 255, 255, 255 ]): Rgb {
  return [
    Math.round(r1 * a + r2 * (1 - a)),
    Math.round(g1 * a + g2 * (1 - a)),
    Math.round(b1 * a + b2 * (1 - a))
  ];
}

/**
 * Mixes a color with alpha channel with a given background color, so that it becomes opaque.
 *
 * The default background color is white.
 */
export function mixColorWithBackground (color: RgbaColor, backgroundColor?: HexColor) {
  return toHexColor(
    mixRgbaWithBackground(parseColor(color), backgroundColor && parseHexColor(backgroundColor))
  );
}

/**
 * Changes the luminosity of a hex color.
 *
 * @param color - A hex color (e.g. '#aaccdd').
 * @param [luminosity] - From -1 to 1. 0.1 makes the color 10% lighter.
 * @return Adjusted hex color with hash.
 */
export function adjustHexLuminosity (color: HexColor, luminosity = 0) {
  assert(!!color, 'The color argument must be specified!');

  let result = '#';
  for (let i = 0; i < 3; i++) {
    let c = parseInt(color.substr(i * 2 + 1, 2), 16); // +1 because of the # prefix
    result += clamp255(c + (c * luminosity)).toString(16).padStart(2, '0');
  }

  return result as HexColor;
}

export function applyOpacityFactors (colors: Color[]) {
  return Object.freeze(colors.map((value, index) => getColorWithOpacity(value, GRADIENT_OPACITY_FACTORS[index])));
}

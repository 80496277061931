import defaultLogger, { type Logger } from 'loglevel';

export interface NamedLogger extends Logger {
  name: string;
}

/**
 * Obtain a named logger. The logger will have the default level "warn".
 *
 * You can specify the log level for a named logger independently from the default logger ot other
 * named loggers. This can be useful for example if you have a very verbose logging for a component
 * and don't want it to be enabled at all times.
 *
 * In order to change the level in development, type the following in the browser's console:
 *
 * disyCommons.logger.getLogger('NameOfTheLogger').setLevel('debug')
 *
 * It changes the level of the logger, and the change is persisted in LocalStorage (with the key
 * "loglevel:NameOfTheLogger") so the logger will keep using the specified level even after a page
 * reload.
 *
 * See: https://github.com/pimterry/loglevel
 *
 * @param name - Name for the logger
 * @return A named logger
 */
function getLogger (name: string): NamedLogger {
  const logger = defaultLogger.getLogger(name) as NamedLogger;
  logger.setDefaultLevel(logger.levels.WARN);
  return logger; // the Logger interface of loglevel does not have the name property although the property is present and readable on the returned object
}

export {
  /**
   * The default logger, used in most places.
   *
   * Supported methods:
   *
   * logger.trace()
   * logger.debug()
   * logger.info()
   * logger.warn()
   * logger.error()
   */
  defaultLogger as logger,

  getLogger
};

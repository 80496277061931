// <GENERATED-CODE>
// Generated from Java using 'npm run generate:ts'

const FEATURE_NAME_VALUES = [
  'ACCESS_MANAGER',
  'ACQUIRE_OBJECTTYPE_DATA',
  'ADD_EXTERNAL_LAYERS',
  'ADD_WFS_LAYER_CLASSIC',
  'ADD_WMS_LAYER_CLASSIC',
  'ADMINISTRATION',
  'ANALYTICS_AGGREGATION_WITH_STD',
  'CADENZA_DEBUGGING',
  'CADENZA_JS_SANDBOX',
  'CLIENT_SIDE_RENDERED_LEGEND',
  'CLIENT_SIDE_LAYER_RENDERING',
  'COPY_OBJECTTYPE_WITH_DATA',
  'COUPLE_MAP_VIEWS',
  'CREATE_EDIT_SKETCH_CATALOG',
  'CREATE_EMPTY_MAP_VIEW',
  'CREATE_OBJECTTYPE_BASED_ON_TEMPLATE',
  'CREATE_PROJECT',
  'CREATE_REPOSITORY',
  'CREATE_ROUTING_REPORT',
  'CREATE_SKETCH_LAYER',
  'CREATE_WORKBOOK_REPORT',
  'CSV_EXPORT',
  'CUSTOM_SKETCH_EDITORS',
  'DATA_PROTECTION',
  'DATASOURCE_ARCGIS_REST',
  'DATASOURCE_ELASTIC_SEARCH',
  'DATASOURCE_SAP_HANA',
  'DATASOURCE_WFS',
  'DELETION_DEADLINE',
  'EDIT_ATTRIBUTE_FUNCTION',
  'EDIT_GROUP',
  'EDIT_REPORT',
  'EDIT_ROLE',
  'EDIT_SKETCH_LAYER',
  'EXCEL_EXPORT',
  'EXCEL_IMPORT_CLASSIC',
  'EXPORT_DATABASE_REPOSITORY',
  'EXPORT_GEOPACKAGE',
  'EXTENDED_PERMALINK_CREATION',
  'GAZETTEER',
  'GEOCODER_IP',
  'GEOCODER_IP_OPERATION',
  'GEOCODING',
  'GEOCODING_STRUCTURED',
  'GEOCODING_UNSTRUCTURED',
  'GUEST_ACCESS',
  'HIDPI_RENDERING',
  'IMPORT_ARCHIVE',
  'IMPORT_DATABASE_REPOSITORY',
  'IMPORT_GEOPACKAGE',
  'IMPORT_PRESETS',
  'JOB_MANAGEMENT',
  'JOB_SUBSCRIPTION',
  'JSON_EXPORT',
  'KML_EXPORT',
  'KML_IMPORT_CLASSIC',
  'LIST_GROUP',
  'LIST_USER',
  'MANAGE_ALL',
  'ANALYTICS_EXTENSIONS',
  'MANAGE_ANALYTICS_EXTENSIONS',
  'MANAGE_DATA_PROTECTION_CONTEXT',
  'MANAGE_DATA_SOURCES',
  'MANAGE_EXTERNAL_LINKS',
  'MANAGE_CURATED_MAP_VIEWS',
  'MANAGE_REPORT_TEMPLATES',
  'MANAGE_WORKBOOK_SNAPSHOTS',
  'MANAGE_LOCATION_FINDER',
  'LOCATION_FINDER',
  'MANAGE_SYSTEM_SETTINGS',
  'MAP_PRINT',
  'MARK_OBJECTTYPE_AS_TEMPLATE',
  'MODIFY_MAP_ANIMATION_CONFIGURATION',
  'OBJECTTYPE_ENRICHMENT',
  'PASSWORD_CHANGE',
  'PERMALINK',
  'PLAY_MAP_ANIMATION',
  'PLAY_MAP_ANIMATION_CLASSIC',
  'PROJECTS_ENABLED',
  'PURPOSE_OF_REQUEST',
  'REACTIVATE_DATA_PROTECTION_CONTEXT',
  'REPOSITORY_RELOAD',
  'REVERSE_GEOCODING',
  'ROUNDTRIP_MEASUREMENT',
  'ROUTING',
  'ROUTING_ISOCHRONES',
  'SELF_SERVICE_IMPORT',
  'SET_DEFAULT_MAP',
  'SHAPEFILE_EXPORT',
  'SHAPEFILE_IMPORT_CLASSIC',
  'SHOW_I18N_TILDES',
  'SIMPLE_ROUTING_PRINTOUT_CLASSIC',
  'SUBSCRIBE_TO_WORKBOOK',
  'TIMELINE',
  'TIMELINE_3D',
  'TIMELINE_3D_CLASSIC',
  'TIMELINE_IMPORT',
  'TIMELINE_IMPORT_CLASSIC',
  'UNPUBLISHED_CADENZA_API',
  'WEBAPP_INTEGRATION',
  'WHAT3WORDS',
  'WORKBOOK',
  'WORKBOOK_CREATION',
  'WORKBOOK_EDIT_ADVANCED_LAYER_SETTINGS',
  'WORKBOOK_EDIT_SPATIAL_CONDITION',
  'WORKBOOK_IMPORT',
  'WORKBOOK_PERFORMANCE_ANALYSIS',
  'WORKBOOK_SNAPSHOTS_USE',
  'WORKBOOK_CHART_REALTIME',
  'WORKBOOK_TRANSFER_OBJECTTYPE_TO_REPOSITORY',
  'WPS',
  'WRITABLE_DATABASE_REPOSITORY',
  'WRITABLE_DATABASE_REPOSITORY_WITH_CMDB',
  'WRITABLE_DATABASE_REPOSITORY_WITH_WRITABLE_FOLDER',
  'WRITABLE_DATABASE_REPOSITORY_WITH_CMDB_AND_WRITABLE_FOLDER',
  'MAIL_SENDING',
  'ADD_LAYER_FROM_NAVIGATOR'
] as const;

/** @see net/disy/cadenza/api/feature/FeatureName.java */
export type FeatureName = typeof FEATURE_NAME_VALUES[number];

export const FeatureName = {
  values: FEATURE_NAME_VALUES as readonly FeatureName[]
} as const;

// </GENERATED-CODE>

export const isFeatureName = (value: string): value is FeatureName => FEATURE_NAME_VALUES.includes(value as never);

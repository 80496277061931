import {
  extendUrlParameters,
  getUrlParam,
  hasParameter,
  setParam
} from 'cadenza/utils/url-param-utils';
import { Observable } from 'cadenza/utils/event-util';

/** @deprecated Use the type instead */
export const BlockIdentifier = {
  SNAPPING_CONTROL: 'snappingControl',
  MOUSE_POSITION: 'mousePosition',
  PROJECTION_DESCRIPTION: 'projectionDescription',
  SCALE_LINE: 'scaleLine',
  SCALE_INPUT: 'scaleInput',
  SCALE_READONLY: 'scaleReadonly',
  OVERVIEW_MAP: 'overviewMap',
  COMPACT_LEGEND: 'compactLegend',
  ZOOM: 'zoom',
  ZOOM_TO_EXTENT: 'zoomToExtent',
  ADD_SHAPEFILE: 'addShapefile',
  LOCATION_SEARCH: 'locationSearch',
  MAP_TOOL_BAR: 'mapToolBar',
  MAP_LEGEND: 'mapLegend',
  UNDO_REDO: 'undoRedo'
} as const;

export type BlockIdentifier = typeof BlockIdentifier[keyof typeof BlockIdentifier];

export type BlockVisibility = 'regular' | 'hidden' | 'minimized';

const BLOCK_SETTINGS_PARAM_NAME = 'blockSettings';

class BlockSettings extends Observable {

  isBlockSettingPresent (identifier: BlockIdentifier) {
    const blockSettings = this._readBlockSettingsFromUrl();
    return Boolean(blockSettings[identifier]);
  }

  isBlockEnabled (identifier: BlockIdentifier, defaultValue?: boolean) {
    const blockSettings = this._readBlockSettingsFromUrl();
    if (!blockSettings[identifier]) {
      return Boolean(defaultValue);
    }
    return blockSettings[identifier].visibility !== 'hidden';
  }

  isBlockVisible (identifier: BlockIdentifier, defaultValue?: boolean) {
    const blockSettings = this._readBlockSettingsFromUrl();
    if (!blockSettings[identifier]) {
      return Boolean(defaultValue);
    }
    return this.isBlockEnabled(identifier) && !this.isBlockMinimized(identifier);
  }

  isBlockMinimized (identifier: BlockIdentifier, defaultValue?: boolean) {
    const blockSettings = this._readBlockSettingsFromUrl();
    if (!blockSettings[identifier]) {
      return Boolean(defaultValue);
    }
    return blockSettings[identifier].visibility === 'minimized';
  }

  setVisibility (identifier: BlockIdentifier, visibility: BlockVisibility) {
    // do not set visibility of control using block-settings in the url for workbook
    // block-settings will be removed from workbook world after split, so a temp solution for now
    if (/\/workbooks\/[^,]+,hash=/.test(location.pathname)) {
      return;
    }
    const blockSettingsFromUrl = this._readBlockSettingsFromUrlFor(identifier);
    blockSettingsFromUrl[identifier].visibility = visibility;
    setParam({
      paramName: BLOCK_SETTINGS_PARAM_NAME,
      paramValue: JSON.stringify(blockSettingsFromUrl)
    });
    this.dispatchEvent(new CustomEvent(`change:${identifier}`));
  }

  _readBlockSettingsFromUrlFor (identifier: BlockIdentifier) {
    const blockSettingsFromUrl = this._readBlockSettingsFromUrl();
    if (!blockSettingsFromUrl[identifier]) {
      blockSettingsFromUrl[identifier] = {};
    }
    return blockSettingsFromUrl;
  }

  _readBlockSettingsFromUrl (): Record<string, { visibility?: BlockVisibility }> {
    return JSON.parse(getUrlParam({ name: BLOCK_SETTINGS_PARAM_NAME })!) || {};
  }

}

const blockSettingsSingleton = new BlockSettings();

export function getBlockSettingsSingleton () {
  return blockSettingsSingleton;
}

export function addCurrentBlockSettingsToUrl (url: string) {
  if (!hasParameter(BLOCK_SETTINGS_PARAM_NAME)) {
    return url;
  } else {
    return extendUrlParameters(url, {
      [BLOCK_SETTINGS_PARAM_NAME]: getUrlParam({ name: BLOCK_SETTINGS_PARAM_NAME })
    });
  }
}

export function isBlockIdentifier (value: string): value is BlockIdentifier {
  return (value in BlockSettings);
}

export class CustomError extends Error {

  detail;

  /**
   * Creates a new CustomError instance
   *
   * @param message - The error message
   * @param [detail] - An optional detail value, e.g. the error cause or an object with additional info
   */
  constructor (message: string, detail?: unknown) {
    if (!message) {
      throw new CustomError('A CustomError message is mandatory');
    }

    super(message);
    this.detail = detail;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }
  }

}

export class AssertionError extends CustomError {
  // nothing special here, but can be expected in tests
}

/**
 * Asserts that a given expression is truthy. Throws an exception if it is not.
 *
 * @param assertion - expression to assert
 * @param message - error message in case the assertion fails
 * @param detail - An optional detail value, e.g. an object with additional info
 */
export function assert (assertion: unknown, message: string, detail?: unknown): asserts assertion {
  if (!assertion) {
    throw new AssertionError(message, detail);
  }
}

/**
 * Asserts the given value not to be `null` or `undefined`.
 *
 * @param value  - The value to assert
 * @param [message] - An optional assertion message
 */
export function assertNonNullable<T> (value: T, message = 'Value is nullish'): asserts value is NonNullable<T> {
  assert(value != null, message, value);
}

/**
 * Asserts that the value is of type if a condition is met.
 *
 * @param value - The value whose type we're trying to assert
 * @param checkOrResult - a condition result or a function returning a condition result
 * @param [message] - The assert message if the value is not of type
 */
export function assertType<T> (
  value: unknown,
  checkOrResult: boolean | ((value: unknown) => boolean),
  message = 'The value is not of the expected type'
): asserts value is T {
  assert(typeof checkOrResult === 'function' ? checkOrResult(value) : checkOrResult, message, value);
}

/**
 * This util can be used to mimic exhaustive switch statements.
 *
 * @example Use `assertUnreachable()` in the default branch of your switch (or equivalent).
 *   function appendNextCharacter (value: AToZ): string {
 *     switch (value) {
 *       'a':
 *         return 'ab';
 *       ...
 *     }
 *     // TypeError if AToZ contains a character
 *     // that you didn't cover in the switch.
 *     return assertUnreachable(value);
 *   }
 * @param detail - The value that is switched over
 * @see https://stackoverflow.com/a/39419171
 */
export function assertUnreachable (detail: never): never {
  throw new CustomError('Didn\'t expect to get here', detail);
}

module.exports = {
  "ok": "OK",
  "apply": "Übernehmen",
  "save": "Speichern",
  "back": "Zurück",
  "cancel": "Abbrechen",
  "clearInput": "Eingabe löschen",
  "close": "Schließen",
  "finish": "Beenden",
  "confirm.title": "Bestätigen",
  "contextHelp.title": "Hilfe",
  "moreActions": "Mehr Funktionen ...",
  "defaultErrorMessage": "Bitte wenden Sie sich an Ihren Anwendungsbetreuer.",
  "error": "Fehler",
  "execute": "Ausführen",
  "delete": "Löschen",
  "dialog.close.title": "Schließen",
  "dialog.noEmbeddedFrames": "Ihr Browser kann leider keine eingebetteten Frames anzeigen.",
  "no": "Nein",
  "share": "Teilen",
  "warning": "Warnung",
  "yes": "Ja",
  "continue": "Fortfahren",
  "noMatchesFound": "Keine Treffer gefunden",
  "noResult": "Die Abfrage liefert keine Ergebnisse. Bitte passen Sie die Filterbelegung an.",
  "select2.selectionTooBig_one": "Es darf maximal ein Wert ausgewählt werden.",
  "select2.selectionTooBig_other": "Es dürfen maximal {{count}} Werte ausgewählt werden.",
  "select2.placeholder": "Suchbegriff eingeben",
  "error.validate.title": "Fehler beim Validieren der Eingaben",
  "error.validate.message": "Beim Validieren der Eingaben ist ein Fehler aufgetreten."
}
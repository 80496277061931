export function degreesToRadians (degrees) {
  return degrees * Math.PI / 180;
}

export function radiansToDegrees (radians) {
  return radians * 180 / Math.PI;
}

/**
 * Rounds the number to decimal digits if the given number is relative small (less than 10^digits - its digit count in its integer part is less than {digits}).
 * If the number is relative large (greater or equal to 10^digits) it is rounded to the nearest integer.
 *
 * @param {number} number - Floating point number to round
 * @param {number} digits - The number of digits to be left after the decimal point
 * @return {number} The rounded number
 * @example
 *   roundFloat(0.98765, 3) = 0.988
 *   roundFloat(610.98444, 3) = 610.984
 *   roundFloat(4887.875, 3) = 4887
 */
export function roundToFloatOrInt (number, digits = 0) {
  const factor = Math.pow(10, digits);
  if (number >= factor) {
    return Math.round(number);
  }
  return Math.round(number * factor) / factor;
}

/**
 * Return true if the values a and b are close to each other and false otherwise.
 *
 * Whether or not two values are considered close is determined according to given absolute and relative tolerances.
 *
 * If no errors occur, the result will be: abs(a-b) <= max(relTol * max(abs(a), abs(b)), absTol).
 *
 * This method is modeled after the python method math.isclose (https://docs.python.org/3/library/math.html#math.isclose).
 * Some background on comparing floating point numbers: https://randomascii.wordpress.com/2012/02/25/comparing-floating-point-numbers-2012-edition/
 *
 * @param {number} a - first number to compare
 * @param {number} b - second number to compare
 * @param {number} [relTol=1e-09] - the relative tolerance – it is the maximum allowed difference between a and b, relative to the larger absolute value of a or b. For example, to set a tolerance of 5%, pass rel_tol=0.05. The default tolerance is 1e-09, which assures that the two values are the same within about 9 decimal digits. rel_tol must be greater than zero.
 * @param {number} [absTol={@link Number.EPSILON}] - the minimum absolute tolerance – useful for comparisons near zero. absTol must be at least zero.
 *     From PEP 485: "The default absolute tolerance value is set to zero because there is no value that is appropriate for the general case. It is impossible to know an appropriate value without knowing the likely values expected for a given use case. If all the values tested are on order of one, then a value of about 1e-9 might be appropriate, but that would be far too large if expected values are on order of 1e-9 or smaller."
 *     A rough approximation of a useful absTol is Number.EPSILON * 1e<max exponent of the numbers involved in calculating the near-zero number>
 * @return {boolean} true if the values a and b are close to each other and false otherwise.
 */
export function isClose (a, b, relTol = 1e-09, absTol = 0.0) {
  return Math.abs(a - b) <= Math.max(relTol * Math.max(Math.abs(a), Math.abs(b)), absTol);
}

/**
 * Convenience method that calls {@link isClose} with sensible defaults for checking if a number is close to zero.
 *
 * @param {number} number - number to compare with zero
 * @param {number} [absTol={@link Number.EPSILON} * 1e10] - the minimum absolute tolerance. absTol must be at least zero.
 *     A rough approximation of a useful absTol is Number.EPSILON * 1e<max exponent of the numbers involved in calculating the near-zero number>
 *     The default value is picked so that is works well for values resulting from calculations with numbers of maximally 10 digits (e.g. coordinates).
 * @return {boolean} true if number is close to 0.0 and false otherwise.
 */
export function isCloseToZero (number, absTol = Number.EPSILON * 1e10) {
  return isClose(number, 0.0, 0.0, absTol);
}

/**
 * Makes sure the given value is inside the specified range.
 *
 * @param {number} x - Given value to be clamped.
 * @param {number} lower - Upper bound.
 * @param {number} upper - Lower bound.
 * @return {number} - Number that is inside the the range.
 */
export function clamp (x, lower, upper) {
  return Math.max(lower, Math.min(upper, x));
}

/**
 * Calculates the angle between two points.
 *
 * @param {[number, number]} a - Point a.
 * @param {[number, number]} b - Point b.
 * @return {number} The angle in the plane (in radians).
 */
export function angle ([ ax, ay ], [ bx, by ]) {
  return Math.atan2(ay - by, ax - bx);
}

/**
 * Calculates the distance between tho points.
 *
 * @param {[number, number]} a - Point a.
 * @param {[number, number]} b - Point b.
 * @return {number} The distance in the plane.
 */
export function distance ([ ax, ay ], [ bx, by ]) {
  const dx = Math.abs(ax - bx);
  const dy = Math.abs(ay - by);
  return Math.hypot(dx, dy);
}
